// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-block-projects-js": () => import("./../../../src/pages/blockProjects.js" /* webpackChunkName: "component---src-pages-block-projects-js" */),
  "component---src-pages-contacto-index-js": () => import("./../../../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proyectos-index-js": () => import("./../../../src/pages/proyectos/index.js" /* webpackChunkName: "component---src-pages-proyectos-index-js" */),
  "component---src-pages-proyectos-our-projects-jsx": () => import("./../../../src/pages/proyectos/OurProjects.jsx" /* webpackChunkName: "component---src-pages-proyectos-our-projects-jsx" */),
  "component---src-pages-proyectos-project-carousel-js": () => import("./../../../src/pages/proyectos/ProjectCarousel.js" /* webpackChunkName: "component---src-pages-proyectos-project-carousel-js" */),
  "component---src-pages-proyectos-workflow-js": () => import("./../../../src/pages/proyectos/workflow.js" /* webpackChunkName: "component---src-pages-proyectos-workflow-js" */)
}

